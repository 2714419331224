import React from "react";
import Layout from "../components/layout";
import YAMLData from '../assets/openapi.yaml';
import SEO from "../components/seo";
import {RedocStandalone} from 'redoc';

const Docs = () => {
    return (
        <Layout>
            <SEO title={'Documentation'}/>
            <div style={{height: '5px'}} className={'bg-orange'}></div>
            <RedocStandalone
                spec={JSON.parse(JSON.stringify(YAMLData))}
                options={{
                    nativeScrollbars: false,
                    "theme": {
                        "colors": {
                            "primary": {
                                "main": "rgba(225, 139, 0, 1)",
                                "light": "rgba(225, 139, 0, 0.4)"
                            },
                            "success": {
                                "main": "rgba(28, 184, 65, 1)",
                                "light": "#81ec9a",
                                "dark": "#083312",
                                "contrastText": "#000"
                            },
                            "text": {
                                "primary": "rgba(0, 0, 0, 1)",
                                "secondary": "#4d4d4d"
                            },
                            "http": {
                                "get": "rgba(0, 200, 219, 1)",
                                "post": "rgba(28, 184, 65, 1)",
                                "put": "rgba(255, 187, 0, 1)",
                                "delete": "rgba(254, 39, 35, 1)"
                            }
                        },
                        "typography": {
                            "fontSize": "14px",
                            "fontFamily": "Fira Sans, Roboto, sans-serif",
                            "optimizeSpeed": true,
                            "smoothing": "antialiased",
                            "headings": {
                                "fontWeight": "bold",
                                "lineHeight": "1em"
                            },
                            "code": {
                                "fontWeight": "600",
                                "color": "rgba(225, 139, 0, 1)",
                                "wrap": true
                            },
                            "links": {
                                "color": "rgba(109, 108, 108, 1)",
                                "visited": "rgba(109, 108, 108, 1)",
                                "hover": "rgba(225, 139, 0, 1)"
                            }
                        },
                        "sidebar": {
                            "backgroundColor": "rgba(255, 255, 255, 1)",
                            "width": "300px",
                            "textColor": "#000000"
                        },
                        "rightPanel": {
                            "backgroundColor": "rgba(33, 31, 31, 1)",
                            "textColor": "#ffffff"
                        },
                        "schema": {
                            "requireLabelColor": "rgba(225, 139, 0, 1)"
                        }
                    },
                    disableSearch: true,
                    hideDownloadButton: true,
                }}
            />
        </Layout>
    )
}

export default Docs